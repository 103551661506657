<template>
  <l-default>
    <OTitle>FAQ</OTitle>
    <CAdNewsletterLong />

    <CFAQALL />

    <CAdNewsletter />
  </l-default>
</template>

<script>
import LDefault from '../../layouts/LDefault'
import OTitle from '../../objects/OTitle'
import CAdNewsletterLong from '@/components/CAdNewsletterLong.vue'
import CAdNewsletter from '@/components/CAdNewsletter.vue'
import CFAQALL from '@/components/CFAQALL.vue'

export default {
  name: 'PageFAQ',
  components: { CFAQALL, CAdNewsletter, CAdNewsletterLong, OTitle, LDefault },
  metaInfo: {
    title: 'Spoof Call And Caller ID Faker | FAQ',
    meta: [
      {
        name: 'description',
        content: 'Spoof Call And Caller ID Faker - FAQ'
      },
      {
        name: 'keywords',
        content: 'myphonerobot faq'
      }
    ]
  }
}
</script>
