<template>
  <div>
    <div class="faq" v-for="group in faq" v-bind:key="'group_'+group.id">
      <h2 class="faq-title">{{ group.name }}</h2>
      <div v-for="f in group.questions" v-bind:key="'faq_'+f.id">
        <h2 class="faq-question">{{ f.question }}</h2>
        <div v-html="f.answer"/>
        <br/>
      </div>
    </div>
  </div>
</template>

<script>

const getApi = () => import('@/services/api')

export default {
  name: 'CFAQ',
  components: {},
  data: () => ({ loading: true, faq: [] }),
  methods: {
    async load () {
      const api = await getApi()
      const result = await api.getFaq()
      if (result.error) {
        this.error = result.message
        return false
      }
      this.faq = result.faq
    }
  },
  mounted () {
    this.load()
  }
}
</script>

<style lang="scss">
.faq a {
  text-transform: none;
}

.faq-question {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 1.17em;
}

.faq-title {
  display: block;
  @include typi('title');
  text-align: center;
  color: $primary;
  padding: 0;
  margin: 0 0 $vertical-margin;
  text-transform: uppercase;
  font-weight: normal;
  position: relative;

  &:before {
    border-top: 1px solid $primary;
  }

  &:after {
    border-bottom: 1px solid $primary;
  }

  &:before, &:after {
    content: "";
    display: block;
    height: vr(0.5);
  }
}
</style>
